import { LogEvent, LogFilter, LogStorage, LogStyle } from '../types'

/**
 * A class that will store log events in memory.
 */
export class MemoryLogStorage implements LogStorage {
  // The list of all log statements.
  private _logs: LogEvent[] = []

  append(type: string, style: LogStyle, ...data: any[]) {
    this._logs.push({
      timestamp: Date.now(),
      data,
      type,
      style,
    })
    return Promise.resolve(undefined)
  }

  getLogs({
    filter,
    // level,
    // startTime,
    // endTime,
  }: LogFilter = {}): Promise<readonly LogEvent[]> {
    if (filter) {
      return Promise.resolve(
        this._logs.filter((l) => {
          const s = l.data.join(' ')
          return s.match(filter)
        }),
      )
    } else {
      return Promise.resolve(this._logs)
    }
  }

  clear() {
    this._logs = []
    return Promise.resolve(undefined)
  }
}
