import React from 'react'
import {
  Pressable as RNPressable,
  type PressableProps as RNPressableProps,
  type ViewStyle,
  type StyleProp,
} from 'react-native'
import { createRestyleComponent } from '@shopify/restyle'
import { BoxRestyleProps, boxRestyleFunctions } from '../../box'
import { ActionStylingProps, genericButtonProps } from './actionStyles'
import { AptTheme } from '~/theme'
import type { InnerActionProps, OnPressCallback } from './types'

export type PressableProps = Omit<RNPressableProps, 'children' | 'style'> &
  BoxRestyleProps & {
    // Pressable can take a function as style but for consistency we don't want
    // to support that in our version of Pressable
    style?: StyleProp<ViewStyle>
    // Pressable can take a function as children but for consistency we don't want
    // to support that in our version of Pressable
    children?: React.ReactNode
  }

export const Pressable = createRestyleComponent<PressableProps, AptTheme>(
  boxRestyleFunctions,
  RNPressable,
)

/**
 * Props that a button should never have. This is used to help warn us that an
 * `href` should never be passed to a button.
 */
interface ButtonNeverProps {
  href?: never
  blank?: never
}

export type ButtonProps = PressableProps &
  ActionStylingProps &
  InnerActionProps &
  ButtonNeverProps & {
    // Require the on press handler
    onPress: OnPressCallback
  }

export type ButtonRef = typeof Pressable

// TODO Rename file Button.tsx
export const Button = React.forwardRef<ButtonRef, ButtonProps>(
  ({ hitSlop, children, ...rest }, ref) => {
    return (
      <Pressable
        {...genericButtonProps({
          ...rest,
          hitSlop:
            hitSlop == null
              ? undefined
              : typeof hitSlop === 'object'
                ? hitSlop
                : {
                    top: hitSlop,
                    right: hitSlop,
                    bottom: hitSlop,
                    left: hitSlop,
                  },
        })}
        ref={ref}
      >
        {children}
      </Pressable>
    )
  },
)
