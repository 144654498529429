import queryString from 'query-string'
import * as Linking from 'expo-linking'
import { matchPath, Location } from './navigation'
import { env } from '~/env'
import { removeNulls } from './utils'

export const Schemas = [
  // Use this shorthand prefix only for testing and storybook
  'aptsnap://',
  'http://snapshot.apartmentsnapshot.com',
  'https://snapshot.apartmentsnapshot.com',
  // Linking.makeUrl fails during testing so we'll hardcode it.
  // TODO It should be working according to https://github.com/expo/expo/issues/11754
  /* istanbul ignore next */
  env.test ? 'apartmentsnapshot://' : Linking.createURL('/'),
]

export interface RouteDefinition {
  /**
   * The title to display in the header.
   */
  title: string
  /**
   * The id used to track the associated screen in analytics. It should be a
   * readable and unique name that never changes.
   */
  analyticsId: string
  /**
   * The list of URLs associated with route. The first URL in the array is
   * treated as the canonical URL. Any URLs after that are used as fallbacks to
   * support deprecated URLs.
   */
  paths: string[]
}

/**
 * A Map of route names to `Route` objects.
 *
 * IMPORTANT: These routes need to be inserted in order of
 * specificity (least specific to most specific) so the
 * `findRouteMatching` method finds the first matching route.
 */
export const Routes = new Map<string, RouteDefinition>([
  // prettier-ignore
  [
    'HOME',
    {
      title: 'Home',
      analyticsId: 'Home', // do not change
      paths: ['/']
    }
  ],
  [
    'SETTINGS',
    {
      title: 'Settings',
      analyticsId: 'Notification Settings', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/settings', '/notifications'],
    },
  ],
  [
    'LEADERBOARD',
    {
      title: 'Leaderboard',
      analyticsId: 'Leaderboard', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard'],
    },
  ],
  [
    'LEADERBOARD_DETAIL',
    {
      title: 'Leaderboard Detail',
      analyticsId: 'Leaderboard Detail', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/:leaderboardConfigId'],
    },
  ],
  [
    'PROPERTIES_LEADERBOARD',
    {
      title: 'Properties Leaderboard',
      analyticsId: 'Properties Leaderboard', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/properties'],
    },
  ],
  [
    'REGIONAL_LEADERBOARD',
    {
      title: 'Regional Leaderboard',
      analyticsId: 'Regional Leaderboard', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/regionals'],
    },
  ],
  [
    'PROPERTIES_BY_REGIONAL_LEADERBOARD',
    {
      title: 'Regional Properties',
      analyticsId: 'Regional Properties', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/regionals/:userId/properties'],
    },
  ],
  [
    'CATEGORY_LEADERBOARD',
    {
      title: 'Category Leaderboard',
      analyticsId: 'Category Leaderboard', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/categories/:categoryId'],
    },
  ],
  [
    'PORTFOLIO_OVERVIEW',
    {
      title: 'Overview',
      analyticsId: 'Portfolio Overview', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/overview', '/leaderboard/metrics'],
    },
  ],
  [
    'PORTFOLIO_SUBMARKET',
    {
      title: 'Submarket',
      analyticsId: 'Portfolio Submarket', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/leaderboard/submarket'],
    },
  ],
  [
    'USER_SCORECARD',
    {
      title: 'User Scorecard',
      analyticsId: 'User Scorecard', // do not change
      paths: ['/leaderboard/scorecards/:reportId/users/:userId'],
    },
  ],
  [
    'REPORT_METRIC_DETAILS',
    {
      title: 'Metric Details',
      analyticsId: 'Report Metric Details', // do not change
      paths: [
        '/leaderboard/scorecards/:reportId/users/:userId/metrics/:metricSlug',
      ],
    },
  ],
  [
    'TEAM_CATEGORIES',
    {
      title: 'Scorecard',
      analyticsId: 'Team Categories', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: [
        '/properties/:propertyId',
        '/team/properties/:propertyId/categories',
      ],
    },
  ],
  [
    'TEAM_METRICS',
    {
      title: 'KPI Details',
      analyticsId: 'Team Metrics', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: [
        '/properties/:propertyId/categories/:categorySlug',
        '/team/properties/:propertyId/categories/:categoryId/metrics',
      ],
    },
  ],
  [
    'TEAM_METRIC_DETAILS',
    {
      title: 'Metric Details',
      analyticsId: 'Metric Details', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: [
        '/properties/:propertyId/categories/:categorySlug/metrics/:metricSlug',
        '/team/properties/:propertyId/categories/:categoryId/metrics:metricId',
      ],
    },
  ],
  [
    'PROPERTY_OVERVIEW',
    {
      title: 'Overview',
      analyticsId: 'Property Overview', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: [
        '/properties/:propertyId/overview',
        '/properties/:propertyId/metrics',
      ],
    },
  ],
  [
    'PROPERTY_SUBMARKET',
    {
      title: 'Submarket',
      analyticsId: 'Property Submarket', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/properties/:propertyId/submarket'],
    },
  ],
  [
    'TROPHY_ROOM',
    {
      title: 'Trophy Room',
      analyticsId: 'Trophy Room', // do not change
      paths: ['/personal'],
    },
  ],
  [
    'PERSONAL_USER_SCORECARD',
    {
      title: 'Personal User Scorecard',
      analyticsId: 'Personal User Scorecard', // do not change
      paths: ['/personal/scorecards/:reportId'],
    },
  ],
  [
    'PERSONAL_METRIC_DETAILS',
    {
      title: 'Personal Metric Details',
      analyticsId: 'Personal Metric Details', // do not change
      paths: ['/personal/scorecards/:reportId/metrics/:metricSlug'],
    },
  ],
  [
    'AWARDS',
    {
      title: 'Awards',
      analyticsId: 'Awards', // do not change
      paths: ['/personal/awards'],
    },
  ],
  [
    'BONUSES',
    {
      title: 'Bonuses',
      analyticsId: 'Bonuses', // do not change
      paths: ['/personal/bonuses'],
    },
  ],
  [
    'CHALLENGE',
    {
      title: 'Challenge',
      analyticsId: 'Challenge', // do not change
      paths: ['/personal/challenge/:challengeSlug'],
    },
  ],
  [
    'CHALLENGE_LEADERBOARD',
    {
      title: 'Challenge Leaderboard',
      analyticsId: 'Challenge Leaderboard', // do not change
      // REMEMBER: URL changes are public API changes. Add redirects for legacy
      // URLs if you are going to change URLs.
      paths: ['/personal/challenge/:challengeSlug/leaderboard'],
    },
  ],
  [
    'CHALLENGES',
    {
      title: 'Challenges',
      analyticsId: 'Challenges', // do not change
      paths: ['/personal/challenges'],
    },
  ],
])

/**
 * Get the route definition for the given route name.
 */
export function getRoute(name: string) {
  return Routes.get(name)
}

/**
 * Get the URL for a specific route with path parameters
 * replaced.
 */
export function getURL(
  /**
   * The name of the route (key) in the Routes map.
   */
  name: string,
  /**
   * An object with the url parameters to replace.  Each key in the parameter
   * should match a variable token in the route (without the leading ':'). If
   * parameters is not passed, the raw path is returned.
   *
   *   Ex: {projectId: 123} will replace the project id token in the route
   *   `/project/:projectId/new` with the value 123.
   */
  urlParams?: Bag,
  /**
   * An object of key/value pairs to use as query parameters on the URL or a
   * query parameter string.
   */
  queryParams?: Bag | string,
  /**
   * The Routes object (only useful during testing.
   */
  mapping = Routes,
) {
  const route = mapping.get(name)
  if (!route) {
    console.error('Could not find route called', name)
    return ''
  }

  // TODO use generatePath from react-router
  let r = route.paths[0]
  if (urlParams) {
    Object.keys(urlParams).forEach(
      (key) => (r = r.replace(`:${key}`, String(urlParams[key]))),
    )
  }

  if (queryParams) {
    if (typeof queryParams === 'object') {
      // Sort the query paramss by key so they are always in the same order
      const queryParamsSorted = Object.keys(queryParams)
        .filter(removeNulls)
        .sort()
        .reduce((obj, key) => {
          // Sometimes query params are passed as undefined, so we need to filter them out
          if (queryParams[key]) obj[key] = queryParams[key]
          return obj
        }, {})

      // Create URLSearchParams object so it automatically converts spaces to + signs instead of %20.
      const searchParams = new URLSearchParams(queryParamsSorted)
      const q = searchParams.toString()
      if (q) r += `?${q}`
    } else if (typeof queryParams === 'string') {
      if (!queryParams.startsWith('?')) queryParams = `?${queryParams}`
      r += `${queryParams}`
    }
  }

  return r
}

/**
 * Get an element from the `Routes` map based on the current browser location.
 */
export function findRouteMatchingLocation(
  /**
   * The URL for which you wish to look up the route object.
   */
  location: string,
  mapping = Routes,
) {
  // Convert the Routes map into objects that contain both the key and value.
  let values = []
  mapping.forEach((value, key) => values.push({ value, key }))
  values = values.reverse()

  // Search the routes in reverse looking for the first route to match the given location.
  for (const route of values) {
    // TODO Run through deprecated routes as well
    const match = matchPath(route.value.paths[0], location)
    // const match = matchPath(location, {
    //   path: route.value.path,
    //   exact: true,
    //   strict: false,
    // })
    if (match) {
      return {
        key: route.key,
        route: route.value,
        match,
      }
    }
  }
  return null
}

/**
 * Check to see if a URL matches one of the routes provided.
 */
export function doesURLMatch(
  /**
   * The URL to match
   */
  url: string,
  /**
   * The list of routes to look through
   */
  routes: RouteDefinition | RouteDefinition[],
) {
  if (!Array.isArray(routes)) routes = [routes]
  const filtered = routes.filter((t) => {
    return matchPath(t.paths[0], url)
  })
  return filtered.length > 0
}

/**
 * Given a route or query parameter name, normalize the value. For example, if
 * the param should be treated as a number, this will convert the value into a
 * number.
 */
export function normalizeParam(name: string, value: string) {
  if (value == null) return ''

  if (/Count$/.test(name)) {
    return Number(value)
  }

  return value
}

/**
 * Call `normalizeParam` for all keys in a parameter object.
 */
export function normalizeAllParams(params: Record<string, string>) {
  if (!params) return null
  const out = {}
  Object.keys(params).forEach((key) => {
    out[key] = normalizeParam(key, params[key])
  })
  return out
}

/**
 * Get a parameter from a parameter object, doing any necessary casting.
 * For convenience, you can pass the react-router `match` object or
 * the `match.params` property.
 */
export function getRouteParam(
  /**
   * The name of the property to retrieve and cast.
   */
  name: string,
  /**
   * The `react-router` match object or an object that contains the expected key.
   */
  match: Bag,
) {
  // Allow passing either the `match` object or
  // the `match.params` object.
  const params = match.params ? match.params : match

  return normalizeParam(name, params[name])
}

/**
 * Get all parameters from the URL match as noramlized values.
 * For convenience, you can pass the react-router `match` object or
 * the `match.params` property.
 */
export function getAllRouteParams(
  /**
   * The `react-router` match object or an object with keys to convert.
   */
  match: Record<string, any>,
) {
  const params = match.params ? match.params : match
  return normalizeAllParams(params)
}

/**
 * Get a parameter from the URL query string.
 */
export function getQueryParam(
  /**
   * The name of the paramerter to retrieve.
   */
  name: string,
  /**
   * The search string from window.location or the location object from react-router.
   */
  search: string | Location,
) {
  const s = (
    typeof search === 'object' && search.search ? search.search : search
  ) as string
  const params = queryString.parse(s) as any

  return normalizeParam(name, params[name])
}

/**
 * Get an object with all of the query params in the search
 * string with all params normalized.
 */
export function getAllQueryParams(
  /**
   * The search params
   */
  search: any,
) {
  if (search && typeof search === 'object' && search.search)
    search = search.search
  if (!search) return null
  const params = queryString.parse(search)
  return normalizeAllParams(params as any)
}

/**
 * Creates a URL for the leaderboard option depending on the entity type.
 */
export const getLeaderboardOptionURL = (
  startDate: string,
  type: string,
  entityId: string,
  reportId?: string,
) => {
  switch (type) {
    case 'property':
    case 'category':
      return getURL('TEAM_CATEGORIES', { propertyId: entityId }, { startDate })
    case 'user':
      return getURL(
        'USER_SCORECARD',
        { reportId: reportId, userId: entityId },
        { startDate },
      )
    case 'regional':
      return getURL(
        'PROPERTIES_BY_REGIONAL_LEADERBOARD',
        { userId: entityId },
        { startDate },
      )
    default:
      return null
  }
}
