import { useIsMounted } from '@thesoulfresh/react-tools'
import React from 'react'
import { Path } from 'react-native-svg'
import { mergeRefs } from '~/utils'
import {
  HEARTBEAT_PATH,
  HEARTBEAT_PATH_LENGTH,
  HOUSE_PATH,
} from './path-defintions'
import { HeartbeatPathProps, HousePathProps } from './types'

const KEYFRAMES_HEARTBEAT_FULL = `
@keyframes logomark-heartbeat-full {
  0% {
    stroke-dasharray: 113;
    stroke-dashoffset: 336;
  }
  90% {
    stroke-dasharray: 113;
    stroke-dashoffset: 113;
  }
  100% {
    stroke-dasharray: 113;
    stroke-dashoffset: 113;
  }
}
`
const KEYFRAMES_HEARTBEAT_PULSE = `
@keyframes logomark-heartbeat-pulse {
  0% {
    stroke-dasharray: 10 113;
    stroke-dashoffset: 10;
  }
  90% {
    stroke-dasharray: 10 113;
    stroke-dashoffset: -113;
  }
  100% {
    stroke-dasharray: 10 113;
    stroke-dashoffset: -113;
  }
}
`

const KEYFRAMES_HOUSE_OPACITY = `
@keyframes logomark-house {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`

export const HeartbeatPath = React.memo(
  React.forwardRef(function AnimatedPath(
    {
      color = 'currentColor',
      duration = 2000,
      animation = 'full',
      state = 'playing',
      onStopped,
      ...props
    }: HeartbeatPathProps,
    ref,
  ) {
    const isMounted = useIsMounted()
    const elRef = React.useRef(null)
    const [playing, setPlaying] = React.useState(state === 'playing')

    // Each time the animation loops, check to see if the play state is now
    // 'stopped'. If it is, prevent the animation from looping again. This
    // ensures that the animation plays completely to the out state when
    // stopping it.
    const onLoop = React.useCallback(() => {
      if (isMounted() && state === 'stopped') {
        setPlaying(false)
        onStopped?.()
      }
    }, [state, onStopped, isMounted])

    React.useEffect(() => {
      if (elRef.current.addEventListener) {
        const el = elRef.current
        el.addEventListener('animationiteration', onLoop)
        return () => {
          el.removeEventListener('animationiteration', onLoop)
        }
      }
    }, [onLoop])

    React.useEffect(() => {
      if (state === 'playing') {
        setPlaying(true)
      }
    }, [state])

    return (
      <>
        <style>
          {KEYFRAMES_HEARTBEAT_FULL}
          {KEYFRAMES_HEARTBEAT_PULSE}
        </style>
        <Path
          d={HEARTBEAT_PATH}
          fill="none"
          stroke={color}
          strokeWidth={3}
          // Initial hidden value
          strokeDasharray={HEARTBEAT_PATH_LENGTH}
          strokeDashoffset={HEARTBEAT_PATH_LENGTH * -1}
          style={{
            // Keyframe animations are defined in `./keyframes.css` and injected
            // into the `<head>` at build time. To see changes to the keyframes,
            // you'll need to restart the dev server.
            animation: playing
              ? `${duration}ms infinite logomark-heartbeat-${animation}`
              : undefined,
          }}
          {...props}
          // @ts-expect-error: forwardedRef will work as expected on web.
          forwardedRef={mergeRefs([ref, elRef])}
        />
      </>
    )
  }),
)

export const HousePath = React.memo(function AnimatedPath({
  color = 'currentColor',
  duration = 2000,
  ...props
}: HousePathProps) {
  return (
    <>
      <style>{KEYFRAMES_HOUSE_OPACITY}</style>
      <Path
        d={HOUSE_PATH}
        fill={color}
        fillRule="nonzero"
        // @ts-expect-error: This will work as expected on web.
        style={{
          // Keyframe animations are defined in `./keyframes.css` and injected
          // into the `<head>` at build time. To see changes to the keyframes,
          // you'll need to restart the dev server.
          animation: `${duration}ms logomark-house`,
        }}
        {...props}
      />
    </>
  )
})
