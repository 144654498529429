import { useFonts as useExpoFonts } from 'expo-font'

export default function useFonts() {
  const [loaded, error] = useExpoFonts({
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFProDisplay-Bold': require('./SF-Pro-Display-Bold.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFProDisplay-Medium': require('./SF-Pro-Display-Medium.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFProDisplay-Regular': require('./SF-Pro-Display-Regular.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFProDisplay-Semibold': require('./SF-Pro-Display-Semibold.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFMono-Bold': require('./SF-Mono-Bold.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFMono-Medium': require('./SF-Mono-Medium.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFMono-Regular': require('./SF-Mono-Regular.ttf'),
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    'SFMono-Semibold': require('./SF-Mono-Semibold.ttf'),
  })
  if (error) console.warn('[useFonts]', error)
  // If there was an error loading the fonts, no problem.
  return loaded || !!error
}
